<template>
    <div class="alarm-info-content">
        <div ref="alarm-info-bottom" class="alarm-info-bottom">
            <div 
                ref="first-item" 
                class="alarm-info-item"
                v-for="(item, index) in alarmData"
                :key="index"
            >
                <div class="alarm-info-date">{{ item.warningDate }}</div>
                <div class="alarm-info-time">{{ item.warningTime }}</div>
                <div class="alarm-info-id" :title="item.batteryId">{{ item.batteryId }}</div>
                <div class="alarm-info-value">{{ item.warningInfo }}</div>
            </div>
        </div>
    </div>
</template>


<script>
import './AlarmInfo.less';
export default {
    name: 'AlarmInfo',
    props: {
        alarmData: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            scrollTop: 0,
            time: null
        }
    },
    methods: {
        scrollTopHandel() {
            const top = this.$refs['alarm-info-bottom'].scrollTop;
            this.scrollTop = top;
            this.$refs['alarm-info-bottom'].scrollTop = top + 1;
            if (this.$refs['alarm-info-bottom'].scrollTop - this.scrollTop === 0) {
                this.$refs['alarm-info-bottom'].scrollTop = 0;
                this.scrollTop = 0;
            }
        }
    },
    mounted() {
        this.time = setInterval(this.scrollTopHandel, 100);
    },
    beforeDestroy() {
        clearInterval(this.time);
    }
}
</script>