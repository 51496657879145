<template>
    <div class="maintenance-data-analysis-chart">
        <div ref="left-chart" class="left-chart">
        </div>
        <div ref="right-chart" class="right-chart">
        </div>
    </div>
</template>

<script>
import * as echarts from 'echarts';
import './MaintenanceDataAnalysisChart.less';
export default {
    name: 'MaintenanceDataAnalysisChart',
    props: {
        chartData: {
            type: Object,
            default: () => ({
                left: {
                    done: 0,
                    unDone: 0
                },
                right: {
                    alarm: 0,
                    order: 0
                }
            })
        }
    },
    data() {
        return {
            leftChart: null,
            rightChart: null
        }
    },
    watch: {
        chartData: (value) => {
            const { left: { done, unDone }, right: { alarm, order }} = value;
            this.leftChart.setOption(this.getLeftOption(done, unDone));
            this.rightChart.setOption(this.getRightOption(alarm, order));
        }
    },
    methods: {
        getLeftOption(done, unDone) {
            const option = {
                legend: {
                    orient: 'vertical',
                    bottom: 5,
                    textStyle: {
                        color: '#00B5ED'
                    },
                    icon: 'circle'
                },
                series: [
                    {
                        type: 'pie',
                        center: ['50%', '40%'],
                        radius: [0, '75%'],
                        top: 10,
                        label: {
                            position: 'inner',
                            fontSize: 14,
                            formatter: '{c}%',
                            color: '#fff'
                        },
                        data: [
                            { 
                                value: done, 
                                name: '完成工单占比',
                                itemStyle: {
                                    color: '#0C81FE'
                                },
                            },
                            { 
                                value: unDone, 
                                name: '未完成工单占比',
                                itemStyle: {
                                    color: '#00E4FF'
                                } 
                            }
                        ],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            };
            return option;
        },
        getRightOption(alarm, order) {
            const option = {
                legend: {
                    orient: 'vertical',
                    bottom: 5,
                    textStyle: {
                        color: '#00B5ED'
                    },
                    icon: 'circle'
                },
                series: [
                    {
                        type: 'pie',
                        center: ['50%', '43%'],
                        radius: ['50%', '75%'],
                        avoidLabelOverlap: false,
                        label: {
                            showAbove: false,
                            fontSize: 12,
                            color: '#fff',
                            formatter: '{c}%',
                            rotate: 0
                        },
                        labelLine: {  
                            length: 3,
                            length2: 5,
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        },
                        data: [
                            { 
                                value: alarm, 
                                name: '故障告警总量占比',
                                itemStyle: {
                                    color: '#9B04F9'
                                },
                            },
                            { 
                                value: order, 
                                name: '故障工单总量占比',
                                itemStyle: {
                                    color: '#00E4FF'
                                },
                            },
                        ]
                    }
                ]
            };
            return option;
        }
    },
    mounted() {
        const { left: { done, unDone }, right: { alarm, order }} = this.chartData;
        const leftChartDom = this.$refs['left-chart'];
        const leftChart = echarts.init(leftChartDom);
        this.leftChart = leftChart;
        leftChart.setOption(this.getLeftOption(done, unDone));

        const rightChartDom = this.$refs['right-chart'];
        const rightChart = echarts.init(rightChartDom);
        this.rightChart = rightChart;
        rightChart.setOption(this.getRightOption(alarm, order));
    }
}
</script>