<template>
    <div 
        ref="dataCenterContainer"
        class="data-center-container"
        :style="pageStyle"
    >
        <div v-if="loading" class="loading-container">
            <div class="loading-container">
                <div id="loading-center">
                    <div id="loading-center-absolute">
                        <div class="object"></div>
                        <div class="object"></div>
                        <div class="object"></div>
                        <div class="object"></div>
                        <div class="object"></div>
                        <div class="object"></div>
                        <div class="object"></div>
                        <div class="object"></div>
                        <div class="object"></div>
                        <div class="object"></div>
                    </div>
                </div>
            </div>
        </div>
        <img class="data-center-border" :src="require('@/assets/img/data-center-border.png')"/>
        <img class="bottom-bg" :src="require('@/assets/img/bottom-bg.png')"/>
        <div class="page-title">智锂物联大数据中心</div>
        <div class="map-container">
            <div class="battery-total-container">
                <div class="top-content">
                    <div class="total-icon"></div>
                    <span>电池总量</span>
                </div>
                <div class="bottom-content">
                    32420<span>个</span>
                </div>
            </div>
            <MapChart :mapData="mapData"/>
            <div class="map-change-container">
                <div :class="{'map-button': true, 'map-button-active': mapButtonType === 1}" @click="changeMapType(1)">站点人员</div>
                <div :class="{'map-button': true, 'map-button-active': mapButtonType === 2}" @click="changeMapType(2)">电池分布图</div>
                <div :class="{'map-button': true, 'map-button-active': mapButtonType === 3}" @click="changeMapType(3)">消防联动</div>
            </div>
        </div>
        <div class="runing-state-container">
            <Panel :title="'运行状态'">
                <RuningState :runingState="runingState"/>
            </Panel>
        </div>
        <div class="battery-performance-container-container">
            <Panel :title="'电池性能&寿命分布'">
                <BatteryPerformanceChart :batteryPerformanceData="batteryPerformanceData"/>
            </Panel>
        </div>
        <div class="battery-temperature-life-container">
            <Panel :title="'运维数据分析'">
                <MaintenanceDataAnalysisChart :chartData="maintenanceData"/>
            </Panel>
        </div>
        <div class="alarm-info-container">
            <Panel :title="'告警信息'">
                <AlarmInfo :alarmData="warningData"/>
            </Panel>
        </div>
        <div class="balanced-container">
            <Panel :title="'故障处置效率'">
                <TroubleshootingChart />
            </Panel>
        </div>
        <div class="battery-usage-container">
            <Panel :title="'电池使用率'">
                <BatteryUsageChart />
            </Panel>
        </div>
        <div class="abnormal-battery-container">
            <Panel :title="'均衡占比'">
                <BalancedProportionChart :balanceData="balanceData"/>
            </Panel>
        </div>
        <div class="data-info-container">
            <div class="data-info-top">
                <DataItem :title="'电池总容量'" :unit="'Kwh'" style="marginLeft: 40px;" :value="batterySummary.batteryTotalCapacity"/>
                <DataItem :title="'物联网数据总量'" :unit="'Gb'" :value="batterySummary.netThingsTotal"/>
                <DataItem :title="'累计减少碳排放'" :unit="'Kg'" :value="batterySummary.carbonEmission"/>
                <DataItem :title="'累计用户服务天数'" :unit="'Day'" :value="batterySummary.attenuationLife"/>
            </div>
            <div class="data-info-bottom">
                <TotalItem :title="'电池运行总时长（小时）'" style="marginLeft: 20px;" :value="batterySummary.totalTime"/>
                <TotalItem :title="'动力运行总里程（km）'" :value="batterySummary.totalMileage"/>
                <TotalItem :title="'电池累计释放能量（Kwh）'" :value="batterySummary.totalFreeEnergy"/>
                <TotalItem :title="'实时运行电池总量（个）'" :value="batterySummary.realBatteryCount"/>
                <TotalItem :title="'电池运维总量（次）'" :value="batterySummary.batteryMaintenanceCount"/>
            </div>
        </div>
    </div>
</template>

<script>
import MapChart from './components/MapChart';
import './Index.less';
import Panel from './components/Panel';
import DataItem from './components/DataItem';
import TotalItem from './components/TotalItem';
import BatteryPerformanceChart from './components/BatteryPerformanceChart';
import BatteryUsageChart from './components/BatteryUsageChart';
import RuningState from './components/RuningState';
import AlarmInfo from './components/AlarmInfo';
import MaintenanceDataAnalysisChart from './components/MaintenanceDataAnalysisChart';
import TroubleshootingChart from './components/TroubleshootingChart';
import BalancedProportionChart from './components/BalancedProportionChart';
import axios from '@/utils/axios';
export default {
    name: 'DataCenter',
    components: {
        MapChart,
        Panel,
        DataItem,
        TotalItem,
        BatteryPerformanceChart,
        BatteryUsageChart,
        RuningState,
        AlarmInfo,
        MaintenanceDataAnalysisChart,
        TroubleshootingChart,
        BalancedProportionChart
    },
    data() {
        return {
            pageStyle: {
                transformOrigin: 'left top 0px'
            },
            pageWidth: 1920,
            pageHigth: 1080,
            mapButtonType: 2,
            loading: true,
            batterySummary: {
                totalTime: 19444815, //电池运行总时长
                totalMileage: 38748120, // 运行总里程
                totalFreeEnergy: 1415081.34, // 累积释放能量
                realBatteryCount: 21828, // 实时运行电池个数
                batteryMaintenanceCount: 27611, // 电池运维总量
                batteryTotalCapacity: 116712, // 电池总容量
                netThingsTotal: 7780.8, // 物联网数据总量
                carbonEmission: 186823.04,  // 减少碳排放
                attenuationLife: 4020080 // 防衰减寿命
            },
            warningData: [],
            mapData: [
                { name: '北京市', value: [116.405338,39.918229], count: 13520 }, 
                { name: '黑龙江哈尔滨', value: [126.535862,45.811436], count: 5680 }, 
                { name: '贵州贵阳', value: [106.640309,26.658543], count: 2670 }, 
                { name: '四川成都', value: [104.029626,30.657312], count: 2850 }, 
                { name: '重庆市', value: [106.530841,29.576786], count: 3130 },
                { name: '江苏苏州', value: [118.797149,32.064084], count: 2710 }, 
                { name: '河南郑州', value: [113.643822,34.770052], count: 1860 }
            ],
            runingState: {
                workCount: 21073, // 工作电池
                alarmCount: 162, // 报警电池
                totalRecharge: 183862, // 累积充电
                totalDischarge: 168542, // 累积放电
                addedToday: 120 // 今日新增
            },
            maintenanceData: {
                left: {
                    done: 98.1,
                    unDone: 1.9
                },
                right: {
                    alarm: 96.2,
                    order: 3.8
                }
            },
            batteryPerformanceData: {
                hight: 94.4,
                mid: 1.9,
                retired: 0.2
            },
            balanceData: {
                balanced: 97.3,
                unBalanced: 2.7
            }
        }
    },
    methods: {
        pageScale() {
            const { clientWidth, clientHeight } = this.$refs.dataCenterContainer;
            const { pageWidth, pageHigth } = this;
            const xScale = clientWidth / pageWidth;
            const yScale = clientHeight / pageHigth;
            const pageStyle = {
                ...this.pageStyle,
                width: `${pageWidth}px`,
                height: `${pageHigth}px`,
                transform: `scale(${xScale}, ${yScale})`
            };
            this.pageStyle = pageStyle;
        },
        changeMapType(type) {
            this.mapButtonType = type;
        },
        getWarningList() {
            axios.get('/api/monitor/warnings').then(rs => {
                this.warningData = rs;
            });
        },
        // 电池总览数据
        getBatterySummary() {
            axios.get('/api/monitor/batterySummary').then(rs => {
                const {
                    carbonEmission,  // 累计减少碳排放
                    charged, // 充电总量
                    contentNum, // 上报数据总量
                    contentSize, // 上报数据大小
                    energyConsumption, // 百公里平均能耗
                    powerConsumption, // 放电总量
                    upMileage, // 运行里程
                    uptime, // 运行时长
                } = rs;
                this.batterySummary = {
                    carbonEmission,  // 累计减少碳排放
                    charged, // 充电总量
                    contentNum, // 上报数据总量
                    contentSize, // 上报数据大小
                    energyConsumption, // 百公里平均能耗
                    powerConsumption, // 放电总量
                    upMileage, // 运行里程
                    uptime, // 运行时长
                };
            })
        },
        // 性能寿命
        getHealthStatusDistribution() {
            axios.get('/api/monitor/healthStatusDistribution').then(rs => {
                console.log(rs, 213221312);
            })
        },
        // 运维状态
        getOperationSummary() {
            axios.get('/api/monitor/operationSummary').then(rs => {
                const {
                    job,
                    status
                } = rs;
            })
        },
        // 处置效率
        getSolveEfficiency() {
            axios.get('/api/monitor/solveEfficiency').then(rs => {
                console.log(rs, 222);
            })
        },
        // 运行状态
        getStatusSummary() {
            axios.get('/api/monitor/statusSummary').then(rs => {
                console.log(rs, 333);
            })
        },

        // 电池运行总时长模拟数据
        randomBatterySummaryTotalTime() {
            let minValue = 19444815;
            const maxValue = 19444815 + 258643;
            setInterval(() => {
                if (value >= maxValue) {
                    minValue = 19444815;
                }
                const value = minValue + 30;
                minValue = value;
                this.batterySummary.totalTime = value;
            }, 5000)
        },
        // 累积总里程模拟数据
        randomBatterySummaryTotalMileage() {
            let minValue = 38748120;
            const maxValue = 38748120 + 1815520;
            setInterval(() => {
                if (value >= maxValue) {
                    minValue = 38748120;
                }
                const value = minValue + 210;
                minValue = value;
                this.batterySummary.totalMileage = value;
            }, 5000)
        },
        // 累积释放能量模拟数据
        randomBatterySummaryTotalFreeEnergy() {
            let minValue = 1415081.34;
            const maxValue = 1415081.34 + 66139.39;
            setInterval(() => {
                if (value >= maxValue) {
                    minValue = 1415081.34;
                }
                const value = (minValue + 7.7).toFixed(2);
                minValue = Number(value);
                this.batterySummary.totalFreeEnergy = Number(value);
            }, 5000)
        },

        // 减少碳排放模拟数据
        randomBatterySummaryCarbonEmission() {
            let minValue = 186823.04;
            const maxValue = 186823.04 + 196076.16;
            setInterval(() => {
                if (value >= maxValue) {
                    minValue = 186823.04;
                }
                const value = (minValue + 22.7).toFixed(2);
                minValue = Number(value);
                this.batterySummary.carbonEmission = Number(value);
            }, 5000)
        },
        // 工作电池模拟
        randomRuningStateWorkCount() {
            setInterval(() => {
                const num = Math.round(Math.random() * (24595 - 21073) + 21073);
                this.runingState.workCount = num;
            }, 5000)
        }
    },
    mounted() {
        this.pageScale();
        this.getWarningList();
        // this.getBatterySummary();
        // this.getHealthStatusDistribution();
        // this.getOperationSummary();
        // this.getSolveEfficiency();
        // this.getStatusSummary();
        this.randomBatterySummaryTotalTime();
        this.randomBatterySummaryTotalMileage();
        this.randomBatterySummaryTotalFreeEnergy();
        this.randomBatterySummaryCarbonEmission();
        this.randomRuningStateWorkCount();
        document.onreadystatechange = () => {
            if (document.readyState === 'complete') {
                this.loading = false;
            }
        };
    }
}
</script>