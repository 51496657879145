<template>
    <div ref="mapChart" class="map-chart-container">
    </div>
</template>

<script>
import './MapChart.less';
import * as echarts from 'echarts'
const mapJson = require('./china.json');
export default {
    name: 'MapChart',
    props: {
        mapData: {
            type: Array,
            default: () => ([])
        }
    },
    data() {
        return {
            map: null
        }
    },
    watch: {
        mapData(value) {
            this.createMap(value);
        }
    },
    methods: {
        createMap(data) {
            if (!this.map) {
                return;
            }
            this.map.setOption({
                label: {
                    color: '#fff'
                },
                tooltip: {
                    show: true, 
                    trigger: 'item', 
                    enterable: true,
                    showContent: true,  
                    alwaysShowContent: true,        //是否显示提示框浮层
                    triggerOn: 'mousemove',//提示框触发的条件(mousemove|click|none)  
                    showDelay: 0,       
                    textStyle: {
                        color: '#00CDFF',
                        fontSize: 16
                    },
                    padding: [0, 10],
                    hideDelay: 10,             //浮层隐藏的延迟
                    formatter: (o) => (o.data) ? `<div class="map-hover-box"><div class="map-hover-mov"><span>${o.data.count}个</span></div><i></i></div>` : '',
                    backgroundColor: '#020A8D',  //提示框浮层的背景颜色。
                    borderColor: "transparent",  //图形的描边颜色
                    borderWidth: 0,
                    transitionDuration: 1,      //提示框浮层的移动动画过渡时间，单位是 s，设置为 0 的时候会紧跟着鼠标移动。
                },
                geo: {
                    type: 'map',
                    map: 'china',
                    zoom: 1,
                    selectedMode: 'single',
                    top: '5%',
                    left: '5%',
                    right: '5%',
                    bottom: -200,
                    itemStyle: {
                        shadowBlur: 1,
                        shadowColor: '#2b67c6',
                        shadowOffsetX: -5,
                        shadowOffsetY: 15,
                        opacity: 0.8
                    },
                },
                series: [
                    {
                        type: 'map',
                        map: 'china',
                        zoom: 1,
                        selectedMode: 'single',
                        top: '5%',
                        left: '5%',
                        right: '5%',
                        bottom: -200,
                        itemStyle: {
                        areaColor: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 0, color: '#196dd7' // 0% 处的颜色
                                }, {
                                    offset: 1, color: '#0e50b0' // 100% 处的颜色
                                }],
                                global: false // 缺省为 false
                            },
                            borderWidth: 2,
                            borderColor: '#02e7fb',
                        },
                        markPoint: {
                            symbol: 'circle'
                        },
                        tooltip: {
                            show: false
                        }
                    },
                    {
                        type: "scatter",
                        coordinateSystem: "geo",
                        data,
                        //自定义图片的 大小
                        symbolSize: [35, 25],
                        //自定义图片的 路径
                        symbol: 'image://position.png',
                        tooltip: {
                            padding: 10,
                            formatter: (data) => { 
                                return `${data.data.name}:${data.data.count}`;
                            }
                        }
                    }
                ]
            });
        }
    },
    mounted() {
        echarts.registerMap('china', mapJson);
        const map = echarts.init(this.$refs.mapChart);
        this.map = map;
        this.createMap(this.mapData);
    }
}
</script>