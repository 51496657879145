<template>
    <div class="total-item-container">
        <div class="total-item-info">
            <div class="total-icon">
            </div>
            <span class="total-title">{{ title }}</span>
        </div>
        <div class="total-value">
            {{ value }}
        </div>
    </div>
</template>

<script>
import './TotalItem.less';
export default {
    name: 'TotalItem',
    props: {
        title: {
            type: String,
            default: '电池运行总时长（小时）'
        },
        value: {
            type: Number,
            default: 34232
        }
    }
}
</script>