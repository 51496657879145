<template>
    <div ref="balanced-proportion-chart-container" class="balanced-proportion-chart-container">
    </div>
</template>

<script>
import './BalancedProportionChart.less';
import * as echarts from 'echarts';
export default {
    name: 'BalancedProportionChart',
    props: {
        balanceData: {
            type: Object,
            default: () => ({
                balanced: 53,
                unBalanced: 47
            })
        }
    },
    data() {
        return {
            chart: null
        }
    },
    watch: {
        balanceData(value) {
            const option = this.getOption(value);
            this.chart.setOption(option);
        }
    },
    methods: {
        getOption(balanceData) {
            const option = {
                legend: {
                    orient: 'vertical',
                    bottom: 'center',
                    right: 20,
                    textStyle: {
                        color: '#00B5ED'
                    },
                    icon: 'rect'
                },
                series: [
                    {
                        type: 'pie',
                        center: ['30%', '45%'],
                        radius: [0, '60%'],
                        roseType: 'radius',
                        top: 10,
                        label: {
                            position: 'inner',
                            fontSize: 14,
                            formatter: '{c}%',
                            color: '#fff'
                        },
                        data: [
                            { 
                                value: balanceData.balanced, 
                                name: '均衡电池',
                                itemStyle: {
                                    color: {
                                        type: 'linear',
                                        x: 0,
                                        y: 0,
                                        x2: 1,
                                        y2: 0,
                                        colorStops: [{
                                            offset: 0, color: '#8DE5FB' // 0% 处的颜色
                                        }, {
                                            offset: 1, color: '#8DE5FB' // 100% 处的颜色
                                        }],
                                        global: false // 缺省为 false
                                    },
                                },
                            },
                            { 
                                value: balanceData.unBalanced, 
                                name: '未均衡电池占比',
                                itemStyle: {
                                    color: {
                                        type: 'linear',
                                        x: 0,
                                        y: 0,
                                        x2: 1,
                                        y2: 0,
                                        colorStops: [{
                                            offset: 0, color: '#E4891F' // 0% 处的颜色
                                        }, {
                                            offset: 1, color: '#E4891F' // 100% 处的颜色
                                        }],
                                        global: false // 缺省为 false
                                    },
                                } 
                            }
                        ],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            };
            return option;
        }
    },
    mounted() {
        const chartDom = this.$refs['balanced-proportion-chart-container'];
        const myChart = echarts.init(chartDom);
        this.chart = myChart;
        myChart.setOption(this.getOption(this.balanceData));
    }
}
</script>