<template>
    <div ref="battery-performance-chart" class="battery-performance-chart">
    </div>
</template>

<script>
import * as echarts from 'echarts';
import './BatteryPerformanceChart.less'
export default {
    name: 'BatteryPerformanceChart',
    props: {
        batteryPerformanceData: {
           type: Object,
           default: () => ({
                hight: 94.4,
                mid: 1.9,
                retired: 0.2
           })
        }
    },
    data() {
        return {
            chart: null
        }
    },
    watch: {
        batteryPerformanceData(value) {
            const option = this.getOption(value);
            this.chart.setOption(option);
        }
    },
    methods: {
        getOption(batteryPerformanceData) {
            const option = {
                legend: {
                    orient: 'vertical',
                    bottom: 'center',
                    right: 20,
                    textStyle: {
                        color: '#00B5ED'
                    },
                    icon: 'roundRect'
                },
                series: [
                    {
                        roseType: 'radius',
                        type: 'pie',
                        center: ['32%', '50%'],
                        radius: ['40%', '75%'],
                        avoidLabelOverlap: false,
                        label: {
                            showAbove: false,
                            fontSize: 12,
                            color: '#fff',
                            formatter: '{c}%',
                            rotate: 0
                        },
                        labelLine: {  
                            length: 3,
                            length2: 5,
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        },
                        data: [
                            { 
                                value: batteryPerformanceData.hight, 
                                name: '高性能区',
                                itemStyle: {
                                    color: '#00E4FF'
                                },
                            },
                            { 
                                value: batteryPerformanceData.mid, 
                                name: '中性能区',
                                itemStyle: {
                                    color: '#FD7270'
                                },
                            },
                            { 
                                value: batteryPerformanceData.retired, 
                                name: '退役观测区',
                                itemStyle: {
                                    color: '#FCB609'
                                },
                            },
                        ]
                    }
                ]
            };
            return option;
        }
    },
    mounted() {
        const chartDom = this.$refs['battery-performance-chart'];
        const myChart = echarts.init(chartDom);
        this.chart = myChart;
        this.chart.setOption(this.getOption(this.batteryPerformanceData));
    }
}
</script>