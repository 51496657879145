<template>
    <div ref="battery-usage-chart-container" class="battery-usage-chart-container">
    </div>
</template>

<script>
import * as echarts from 'echarts';
import './BatteryUsageChart.less';
export default {
    name: 'BatteryUsageChart',
    methods: {
        getOption() {
            const option = {
                color: ['#dbec98', '#00DDFF'],
                legend: {
                    top: 10,
                    data: ['运行', '非运行'],
                    textStyle: {
                        color: '#01AAF2'
                    }
                },
                grid: {
                    top: 40,
                    left: 30,
                    bottom: 30,
                    right: 10
                },
                xAxis: {
                    type: 'category',
                    data: ['04-19', '04-20', '04-21', '04-22', '04-23', '04-24'],
                    axisLabel: {
                        color: '#21abfa',
                        fontSize: 12,
                        fontWeight: 700
                    },  
                    axisLine: {
                        lineStyle: {
                            color: '#0C89AD',
                            opacity: 0.3
                        }
                    },
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        color: '#21abfa',
                        fontSize: 12,
                        fontWeight: 700
                    }, 
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#0C89AD',
                            opacity: 0.3
                        }
                    },
                    splitLine: {
                        lineStyle: {
                            color: ['#094B85'],
                            opacity: 0.3
                        }
                    }
                },
                series: [{
                    name: '运行',
                    data: [81, 79, 82, 86, 76, 80],
                    type: 'bar',
                    showBackground: true,
                    backgroundStyle: {
                        color: '#002196'
                    },
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(
                            0, 0, 0, 1,
                            [
                                { offset: 0, color: '#00ACF6' },   
                                { offset: 1, color: 'rgba(62, 199, 76, 0)' }
                            ]
                        ),
                        borderRadius: [50, 50, 0, 0] 
                    },
                    barWidth: 12
                },
                {
                    name: '非运行',
                    data: [19, 21, 18, 14, 24, 20],
                    type: 'bar',
                    showBackground: true,
                    backgroundStyle: {
                        color: '#002196'
                    },
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(
                            0, 0, 0, 1,
                            [
                                { offset: 0, color: '#D6EF8C' },   
                                { offset: 1, color: 'rgba(62, 199, 76, 0)' }
                            ]
                        ),
                        borderRadius: [50, 50, 0, 0] 
                    },
                    barWidth: 12
                }]
            };
            return option;
        }
    },
    mounted() {
        const chartDom = this.$refs['battery-usage-chart-container'];
        const myChart = echarts.init(chartDom);
        myChart.setOption(this.getOption());
    }
}
</script>