<template>
    <div class="data-item-container">
        <div class="data-item-pannel">
            <div class="top-title">{{ title }}</div>
            <div class="bottom-unit">{{ unit }}</div>
            <!-- <img :src="require('@/assets/dashboard-img/data-item-panel.png')"/> -->
        </div>
        <div class="data-item-bottom">
            <span class="value-span">{{ value }}</span>
            <img :src="require('@/assets/dashboard-img/data-item-icon.png')"/>
        </div>
    </div>
</template>

<script>
import './DataItem.less';
export default {
    name: 'DataItem',
    props: {
        title: {
            type: String,
            default: '电池上报数据'
        },
        unit: {
            type: String,
            default: '总量（GB）'
        },
        value: {
            type: Number,
            default: 0
        }
    }
}
</script>