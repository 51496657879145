<template>
    <div class="runing-state-content">
        <div class="runing-state-item">
            <div class="runing-icon">
                工作电池
            </div>
            <div class="runing-progress-bg">
                <div class="runing-progress-value1"></div>
            </div>
            <div class="runging-state-value">
                <img :src="require('@/assets/dashboard-img/battery-1.png')"/>
                <span>{{runingState.workCount}}个</span>
            </div>
        </div>
        <div class="runing-state-item">
            <div class="runing-icon">
                告警电池
            </div>
            <div class="runing-progress-bg">
                <div class="runing-progress-value"></div>
            </div>
            <div class="runging-state-value">
                <img :src="require('@/assets/dashboard-img/battery-2.png')"/>
                <span>{{runingState.alarmCount}}个</span>
            </div>
        </div>
        <div class="runing-state-item">
            <div class="runing-icon">
                累计充电
            </div>
            <div class="runing-progress-bg">
                <div class="runing-progress-value-max"></div>
            </div>
            <div class="runging-state-value">
                <img :src="require('@/assets/dashboard-img/battery-3.png')"/>
                <span>{{runingState.totalRecharge}}kWh</span>
            </div>
        </div>
        <div class="runing-state-item">
            <div class="runing-icon">
                累计放电
            </div>
            <div class="runing-progress-bg">
                <div class="runing-progress-value-max"></div>
            </div>
            <div class="runging-state-value">
                <img :src="require('@/assets/dashboard-img/battery-4.png')"/>
                <span>{{runingState.totalDischarge}}kWh</span>
            </div>
        </div>
        <div class="runing-state-item">
            <div class="runing-icon">
                今日新增
            </div>
            <div class="runing-progress-bg">
                <div class="runing-progress-value-max"></div>
            </div>
            <div class="runging-state-value">
                <img :src="require('@/assets/dashboard-img/battery-5.png')"/>
                <span>{{runingState.addedToday}}个</span>
            </div>
        </div>
    </div>
</template>

<script>
import './RuningState.less';
export default {
    name: 'RuningState',
    props: {
        runingState: {
            type: Object,
            default: () => ({
                workCount: 21073, // 工作电池
                alarmCount: 261, // 报警电池
                totalRecharge: 83862.8, // 累积充电
                totalDischarge: 68542.1, // 累积放电
                addedToday: 120 // 今日新增
            })
        }
    }
}
</script>