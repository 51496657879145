<template>
    <div class="dashboard-pannel-container">
        <div class="dashboard-pannel-title">
            <img class="title-icon" :src="require('@/assets/dashboard-img/pannel-title-icon.png')"/>
            <span class="title-name">{{ title }}</span>
        </div>
        <div class="dashboard-border">
            <img class="border-icon" :src="require('@/assets/dashboard-img/pannel-border-icon.png')"/>
        </div>
        <div class="dashboard-content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import './Panel.less';
export default {
    name: 'Panel',
    props: {
        title: {
            type: String,
            default: '运行状态'
        }
    }
}
</script>