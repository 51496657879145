<template>
    <div ref="troubleshooting-chart-container" class="troubleshooting-chart-container">
    </div>
</template>

<script>
import * as echarts from 'echarts';
import './TroubleshootingChart.less';
export default {
    name: 'TroubleshootingChart',
    methods: {
        getOption() {
            const option = {
                color: ['#D5EE8C', '#F46E71', '#00E4FF', '#C38DF5'],
                legend: {
                    top: 10,
                    data: ['总时间', '处置时间', '产生响应时间', '工单响应时间'],
                    textStyle: {
                        color: '#01AAF2',
                        fontSize: 10
                    },
                    align: 'left',
                    itemWidth: 25,
                    orient: 'horizontal',
                },
                grid: {
                    top: 50,
                    left: 30,
                    bottom: 80,
                    right: 10
                },
                xAxis: {
                    type: 'category',
                    data: ['2天', '4天', '6天', '8天', '10天', '12天'],
                    axisLabel: {
                        color: '#21abfa',
                        fontSize: 12,
                        fontWeight: 700
                    },  
                    axisLine: {
                        lineStyle: {
                            color: '#0C89AD',
                            opacity: 0.3
                        }
                    },
                    boundaryGap: false,
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        color: '#21abfa',
                        fontSize: 12,
                        fontWeight: 700,
                        formatter: '{value}h'
                    }, 
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#0C89AD',
                            opacity: 0.3
                        }
                    },
                    splitLine: {
                        lineStyle: {
                            color: ['#094B85'],
                            opacity: 0.3
                        }
                    }
                },
                series: [
                    {
                        name: '总时间',
                        data: [1, 3, 2, 3, 3, 3],
                        type: 'line',
                        smooth: true,
                        showSymbol: false,
                        lineStyle: {
                            color: '#D5EE8C',
                            width: 4
                        }
                    },
                    {
                        name: '处置时间',
                        data: [2, 1, 2, 3, 2, 3],
                        type: 'line',
                        smooth: true,
                        showSymbol: false,
                        lineStyle: {
                            color: '#F46E71',
                            width: 4
                        }
                    },
                    {
                        name: '产生响应时间',
                        data: [3, 2, 3, 3, 1, 1],
                        type: 'line',
                        smooth: true,
                        showSymbol: false,
                        lineStyle: {
                            color: '#00E4FF',
                            width: 4
                        }
                    },
                    {
                        name: '工单响应时间',
                        data: [1, 2, 2, 3, 4, 2],
                        type: 'line',
                        smooth: true,
                        showSymbol: false,
                        lineStyle: {
                            color: '#C38DF5',
                            width: 4
                        }
                    },
                ]
            };
            return option;
        }
    },
    mounted() {
        const chartDom = this.$refs['troubleshooting-chart-container'];
        const myChart = echarts.init(chartDom);
        myChart.setOption(this.getOption());
    }
}
</script>